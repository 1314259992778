
















































import Vue from 'vue';
import firebase from 'firebase';
import { RawLocation } from 'vue-router';

export default Vue.extend({
  name: 'Login',

  data: () => ({
    email: '',
    password: '',

    submitting: false,
    valid: false,

    alert: false,

    error: '',
  }),

  created() {
    // Logout se l'utente è già loggato
    if (this.user && this.user.loggedIn) {
      firebase.auth().signOut().then(() => {
        console.log('firebase.auth().signOut() success'); // eslint-disable-line no-console
      });
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
  },

  methods: {
    login() {
      if (this.form.validate()) {
        this.submitting = true;

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            console.log('firebase.auth().setPersistence() success'); // eslint-disable-line no-console

            return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
              .then((user) => {
                console.log('firebase.auth().signInWithEmailAndPassword() success'); // eslint-disable-line no-console
                console.log(user); // eslint-disable-line no-console

                if (this.$route.query.redirect) {
                  this.$router.replace(this.$route.query.redirect as RawLocation);
                } else {
                  this.$router.replace('/');
                }
              })
              .catch((error) => {
                console.log('firebase.auth().signInWithEmailAndPassword() error'); // eslint-disable-line no-console
                console.log(error); // eslint-disable-line no-console

                this.submitting = false;

                switch (error.code) {
                  case 'auth/invalid-email':
                    this.error = 'L\'indirizzo email non è nel formato corretto';
                    break;

                  case 'auth/user-not-found':
                    this.error = 'Non esiste un utente con questo indirizzo email';
                    break;

                  case 'auth/wrong-password':
                    this.error = 'La password non è corretta';
                    break;

                  default:
                    this.error = `Errore sconosciuto (${error.code})`;
                    break;
                }

                this.alert = true;
              });
          })
          .catch((error) => {
            console.log('firebase.auth().setPersistence() error'); // eslint-disable-line no-console
            console.log(error); // eslint-disable-line no-console
          });
      }
    },
  },
});

