


























































import Vue from 'vue';
import { NavigationGuardNext } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';

export default Vue.extend({
  name: 'NuovaScuola' as string,

  components: {
    PageHeader,
    // School,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Scuole',
        exact: true,
        to: { name: 'Scuole' },
      },
      {
        text: 'Nuova',
        disabled: true,
      },
    ],

    updating: false,

    school: {
      name: '',
    },

    updated: false,
    leaveDialog: false,
    next: null as NavigationGuardNext | null,
  }),

  methods: {
    cancel() {
      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Scuole' }, () => {});
    },

    async add() {
      this.updating = true;
      this.updated = false;

      await this.$store.dispatch('addSchool', {
        name: this.school.name,
      });

      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Scuole' }, () => {});
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
});
