import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
import { User, UserDetails } from '@/types';

@Module
export default class Auth extends VuexModule {
  currentUser = {
    firstName: null,
    lastName: null,
    email: null,

    // Identificativo della scuola a cui è associato l'utente
    schoolId: null,

    loggedIn: false,
    isSuper: false,
    uid: null,

    firebaseUser: null,
  } as User;

  get user(): User {
    return this.currentUser;
  }

  @Mutation
  setFirebaseUser(user: firebase.User) {
    if (user && user.uid) {
      this.currentUser.email = user.email;
      this.currentUser.loggedIn = true;
      this.currentUser.uid = user.uid;
      this.currentUser.firebaseUser = user;
    } else {
      // Manca l'utente Firebase
      this.currentUser.email = null;
      this.currentUser.loggedIn = false;
      this.currentUser.uid = null;
      this.currentUser.firebaseUser = null;

      this.currentUser.isSuper = false;
    }
  }

  @Mutation
  setUserDetails(userDetails: UserDetails) {
    this.currentUser.firstName = userDetails.firstName;
    this.currentUser.lastName = userDetails.lastName;
    this.currentUser.schoolId = userDetails.schoolId;
    this.currentUser.isSuper = userDetails.isSuper === true;
  }

  @Action
  fetchUser(user: firebase.User) {
    console.log('actions:auth/fetchUser'); // eslint-disable-line no-console

    this.context.commit('setFirebaseUser', user);

    if (user && user.uid) {
      // Recupera le informazioni aggiuntive sull'utente
      const db = firebase.firestore();

      db.collection('users').where('uid', '==', user.uid).limit(1)
        .get()
        .then((querySnapshot) => {
          let userDetails = null;

          if (!querySnapshot.empty) {
            const queryDocumentSnapshot = querySnapshot.docs[0];
            userDetails = queryDocumentSnapshot.data();
          }

          if (userDetails) {
            this.context.commit('setUserDetails', userDetails);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error getting documents: ', error);
        });
    }
  }
}
