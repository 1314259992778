


























































































import Vue from 'vue';
import { NavigationGuardNext } from 'vue-router';
import Loading from '@/components/Loading.vue';
import PageHeader from '@/components/PageHeader.vue';
import SchoolComponent from '@/components/School.vue';
import { School } from '@/types';

export default Vue.extend({
  name: 'Scuola' as string,

  components: {
    Loading,
    PageHeader,
    SchoolComponent,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,

    breadcrumbs: [
      {
        text: 'Scuole',
        exact: true,
        to: { name: 'Scuole' },
      },
    ],

    id: null as string | null,
    school: null as School | null,

    leaveDialog: false,
    next: null as NavigationGuardNext | null,
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const school = await this.$store.dispatch('getSchoolById', this.id);

    this.school = {
      id: this.id,
      ...school,
    } as School;

    // Inserisce il nome della scuola nei breadcrumbs
    this.breadcrumbs.push({
      text: this.school.name,
      exact: false,
      to: { name: 'Scuola' },
    });

    this.loading = false;
  },

  methods: {
    onCancel() {
      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Scuole' }, () => {});
    },

    async archive(value: boolean) {
      if (this.school) {
        this.updating = true;
        this.$set(this.school, 'archived', value);
        await this.$store.dispatch('updateSchool', {
          id: this.id,
          archived: value,
        });
        this.updating = false;
      }
    },

    async update() {
      if (this.school && this.school.name && this.id) {
        this.updating = true;

        const update = {
          id: this.id,
          name: this.school.name,
        };

        await this.$store.dispatch('updateSchool', update);

        // Aggiorna il nome della scuola nei breadcrumbs
        this.breadcrumbs.pop();
        this.breadcrumbs.push({
          text: this.school.name,
          exact: false,
          to: { name: 'Scuola' },
        });

        this.updated = false;
        this.updating = false;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
});
