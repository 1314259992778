import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import firebase from 'firebase';
import Home from '@/views/Home.vue';
import Alunno from '@/views/Alunno.vue';
import Login from '@/views/Login.vue';
import Scuole from '@/views/Scuole.vue';
import NuovaScuola from '@/views/NuovaScuola.vue';
import Scuola from '@/views/Scuola.vue';
import Utenti from '@/views/Utenti.vue';
import NuovoUtente from '@/views/NuovoUtente.vue';
import Utente from '@/views/Utente.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/admin',
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/alunno/:schoolId/:studentId',
    name: 'Alunno',
    component: Alunno,
  },
  {
    path: '/admin',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin/scuole',
    name: 'Scuole',
    component: Scuole,
  },
  {
    path: '/admin/scuole/nuova',
    name: 'NuovaScuola',
    component: NuovaScuola,
  },
  {
    path: '/admin/scuole/:id',
    name: 'Scuola',
    component: Scuola,
  },
  {
    path: '/admin/utenti',
    name: 'Utenti',
    component: Utenti,
  },
  {
    path: '/admin/utenti/nuovo',
    name: 'NuovoUtente',
    component: NuovoUtente,
  },
  {
    path: '/admin/utenti/:id',
    name: 'Utente',
    component: Utente,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path) && !to.path.startsWith('/alunno/');
  // const superUserRequired = to.path.startsWith('/admin/utenti')
  //  || to.path.startsWith('/admin/scuole');
  const { currentUser } = firebase.auth();

  if (authRequired) {
    // Se è richiesta l'autenticazione e l'utente non è loggato...
    if (!currentUser) {
      // ... rimanda alla pagina per il login.
      return next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    }

    // if (superUserRequired) {
    //   currentUser.getIdTokenResult()
    //     .then((idTokenResult) => {
    //       // Confirm the user is an Admin.
    //       if (!idTokenResult.claims.admin) {
    //         // Show regular user UI.
    //         return next({
    //           path: '/admin',
    //         });
    //       }
    //       return next();
    //     })
    //     .catch((error) => {
    //       // eslint-disable-next-line no-console
    //       console.log(error);
    //     });
    // }
  }

  return next();
});

export default router;
