















































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Place, Student } from '@/types';
import Rating from '@/components/Rating.vue';

@Component({
  components: {
    Rating,
  },
})
export default class AlunnoLuoghi extends Vue {
  @Prop(Object) readonly student!: Student;

  loading = true
  places: Place[] = []

  selected: Place | null = null

  map: google.maps.Map | null = null
  mapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
  infoWindow: google.maps.InfoWindow | null = null
  markers: google.maps.Marker[] = []

  watchId: number | null = null
  currentPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
  currentPositionButtonDisabled = false
  currentPositionMarker: google.maps.Marker | null = null

  async created() {
    this.loading = true;

    console.log('this.$route.params = ', this.$route.params); // eslint-disable-line no-console

    this.places = await this.$store.dispatch('getPlaces', {
      schoolId: this.$route.params.schoolId,
    });

    this.initMap();

    this.loading = false;
  }

  get currentLat() {
    return this.currentPosition.lat;
  }

  get currentLng() {
    return this.currentPosition.lng;
  }

  @Watch('currentLat')
  onCurrentLatChange(val: Place[]) {
    this.updateCurrentPosition();
  }

  @Watch('currentLng')
  onCurrentLngChange(val: Place[]) {
    this.updateCurrentPosition();
  }

  @Watch('places')
  onPlacesChange(val: Place[]) {
    console.log('AlunnoLuoghi: watch(value)'); // eslint-disable-line no-console

    if (val.length > 0) this.updateMarkers();
  }

  placeDetails(id: string) {
    return this.places.find((p: any) => p.id === id);
  }

  placeName(id: string) {
    const details = this.placeDetails(id);
    return details && 'name' in details ? details.name : '';
  }

  placeRating(place: Place) {
    if (!Array.isArray(this.places)) return null;

    return this.student.places.find((element: any) => element.id === place.id);
  }

  ratingValue(place: Place) {
    if (!place || 'rating' in place === false
      || ('count' in place && (place as any).count === 0)) return 0;
    return (place as any).rating || 0;
  }

  done(rating: any) {
    // Manca l'oggetto, condotta non eseguita
    if (!rating) return false;

    if ('count' in rating && rating.count > 0) return true;

    if ('date' in rating && rating.date) return true;

    return false;
  }

  watchCurrentPosition() {
    console.log('AlunnoLuoghi: watchCurrentPosition()'); // eslint-disable-line no-console

    // Disattiva eventuali controlli continuativi attivi
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
    }

    // Attiva il controllo continuativo della posizione
    this.watchId = navigator.geolocation.watchPosition((cPosition) => {
      this.currentPosition = {
        lat: cPosition.coords.latitude,
        lng: cPosition.coords.longitude,
      };
    });
  }

  updateCurrentPosition() {
    console.log('AlunnoLuoghi: updateCurrentPosition()'); // eslint-disable-line no-console

    if (this.currentPositionMarker !== null) {
      this.currentPositionMarker.setVisible(true);
      this.currentPositionMarker.setPosition(this.currentPosition);
    }
  }

  initMap() {
    this.map = new window.google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: this.mapCenter,
      zoom: 3,
      maxZoom: 20,
      minZoom: 3,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.BOTTOM_CENTER,
      },
      zoomControl: true,
    });

    const cPosIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/current_position.png'),
      scaledSize: new window.google.maps.Size(24, 24),
    };

    this.currentPositionMarker = new window.google.maps.Marker({
      map: this.map,
      icon: cPosIcon,
    });

    const controlUI = document.createElement('div');

    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '0';
    controlUI.style.borderRadius = '2px';
    controlUI.style.height = '40px';
    controlUI.style.boxShadow = 'rgba(0, 0, 0, .3) 0 1px 4px -1px';
    controlUI.style.cursor = 'pointer';
    controlUI.style.margin = '10px';
    controlUI.style.textAlign = 'center';
    controlUI.title = 'Mostra la tua posizione attuale';

    this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement('div');

    controlText.style.color = 'rgb(25,25,25)';
    controlText.style.fontFamily = 'Roboto, Arial, sans-serif';
    controlText.style.fontSize = '16px';
    controlText.style.lineHeight = '38px';
    controlText.style.paddingLeft = '10px';
    controlText.style.paddingRight = '10px';
    controlText.style.paddingTop = '1px';
    controlText.innerHTML = 'Mostra Posizione';
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      console.log('showCurrentLocation()'); // eslint-disable-line no-console

      // this.currentPositionButtonDisabled = true;

      // Se non conosco ancora la posizione attuale...
      if (this.watchId === null) {
        // Recupero la posizione e avvio il monitoraggio tramite il browser

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              // Posiziona la mappa sulla posizione attuale
              if (this.map) this.map.panTo(this.currentPosition);

              this.watchCurrentPosition();
            },
            () => {
              // handleLocationError(true, infoWindow, map.getCenter());
            },
          );
        }
      } else {
        // Conosco già la posizione attuale

        // Posiziona la mappa sulla posizione attuale
        // eslint-disable-next-line no-lonely-if
        if (this.map) this.map.panTo(this.currentPosition);
      }
    });

    window.google.maps.event.addListener(this.map, 'click', () => {
      if (this.infoWindow) this.infoWindow.close();
      this.selected = null;
    });

    // Crea la InfoWindow che verrà riciclata per tutti i marker
    this.infoWindow = new window.google.maps.InfoWindow({
      maxWidth: 350,
      content: '',
    });

    window.google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
      this.selected = null;
      // then, remove the infowindows name from the array
    });
  }

  setMarker(position: google.maps.LatLngLiteral, icon: google.maps.Icon) {
    const marker = new window.google.maps.Marker({
      position,
      map: this.map,
      icon,
    });

    return marker;
  }

  updateMarkers() {
    console.log('AlunnoLuoghi: updateMarkers()'); // eslint-disable-line no-console

    const greyIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/marker_grey.png'),
    };

    const blueIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/marker_blue.png'),
    };

    const greenIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/marker_green.png'),
    };

    const yellowIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/marker_yellow.png'),
    };

    const redIcon: google.maps.Icon = {
      // eslint-disable-next-line global-require
      url: require('@/assets/marker_red.png'),
    };

    const bounds = new window.google.maps.LatLngBounds();

    this.places
      .filter((place) => 'position' in place && place.position && 'lat' in place.position && 'lng' in place.position)
      .forEach((place) => {
        let icon: google.maps.Icon = greyIcon;

        const placeRating = this.placeRating(place);

        if (placeRating) {
          const rating = this.ratingValue(placeRating);
          switch (rating) {
            case 1:
              icon = redIcon;
              break;
            case 2:
              icon = yellowIcon;
              break;
            case 3:
              icon = greenIcon;
              break;

            default:
              // Eseguita ma non valutata
              icon = blueIcon;
              break;
          }
        }

        const marker = this.setMarker(place.position as google.maps.LatLngLiteral, icon);

        (marker as any).placeId = place.id;

        this.markers.push(marker);

        marker.addListener('click', () => {
          console.log('AlunnoLuoghi: marker clicked'); // eslint-disable-line no-console

          const html = this.infoWindowContent(place);

          if (this.infoWindow) {
            this.infoWindow.setContent(html);
            this.infoWindow.open(this.map, marker);
          }

          this.selected = place;
        });

        const position = marker.getPosition();

        if (position) bounds.extend(position);
      });

    if (this.map) {
      this.map.setCenter(bounds.getCenter());
      this.map.fitBounds(bounds);
    }
  }

  executionsText(place: Place) {
    const placeRating = this.placeRating(place);

    if (!placeRating) return 'Non visitato';

    if ('count' in placeRating === false) return '1 esecuzione';

    if (('count' in placeRating) && (placeRating as any).count === 1) {
      return '1 esecuzione';
    }
    if (('count' in placeRating) && (placeRating as any).count > 1) {
      return `${(placeRating as any).count} esecuzioni`;
    }
    return 'Non visitato';
  }

  infoWindowContent(place: Place) {
    return '<div id="content">'
      + `<h4 id="firstHeading" class="firstHeading">${this.placeName(place.id)}</h4>`
      + '<div id="bodyContent">'
      + `<p>${this.executionsText(place)}</p>`
      + '</div>'
      + '</div>';
  }

  selectPlace(place: Place) {
    console.log('AlunnoLuoghi: selectPlace()'); // eslint-disable-line no-console

    if (this.infoWindow) this.infoWindow.close();
    this.selected = place;

    const marker = this.markers.find((m) => (m as any).placeId === place.id);

    if (marker) {
      const html = this.infoWindowContent(place);

      if (this.infoWindow) {
        this.infoWindow.setContent(html);
        this.infoWindow.open(this.map, marker);
      }
    }
  }
}
