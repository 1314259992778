







import Vue from 'vue';
import Stars from '@/components/Stars.vue';

export default Vue.extend({
  name: 'Rating',

  props: ['item', 'noText'],

  data: () => ({
  }),

  components: {
    Stars,
  },

  computed: {
    ratingValue() {
      if (!this.item || 'rating' in this.item === false
        || ('count' in this.item && (this.item as any).count === 0)) return 0;
      return (this.item as any).rating || 0;
    },

    executionsText() {
      if (!this.item) return this.noText;

      if ('count' in this.item === false) return '1 esecuzione';

      if (('count' in this.item) && (this.item as any).count === 1) {
        return '1 esecuzione';
      }
      if (('count' in this.item) && (this.item as any).count > 1) {
        return `${(this.item as any).count} esecuzioni`;
      }
      return this.noText;
    },
  },

  methods: {
  },
});
