/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
// import { v4 as uuidv4 } from 'uuid';
import { User, CreateUserResult } from '@/types';

@Module
export default class Users extends VuexModule {
  data: User[] = []

  listener: (() => void) | null = null

  get users(): User[] {
    return Object.values(this.data);
  }

  @Mutation
  setUsers({ snapshot }: {
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  }) {
    const data: User[] = [];

    if (snapshot) {
      snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
        } as User);
      });
    }

    this.data = data;
  }

  @Mutation
  setUsersListener({ listener }: { listener: (() => void) | null }) {
    this.listener = listener;
  }

  @Action
  initUsers() {
    console.log('actions:initUsers'); // eslint-disable-line no-console
    return this.context.dispatch('updateUsers');
  }

  @Action
  destroyUsers() {
    console.log('actions:destroyUsers'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();
  }

  @Action({ rawError: true })
  async updateUsers() {
    console.log('actions:updateUsers'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (this.listener) this.listener();

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('users');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (users)'); // eslint-disable-line no-console

      this.context.commit('setUsers', { snapshot });
    });

    this.context.commit('setUsersListener', { listener });
  }

  @Action({ rawError: true })
  async addUser({ user }: { user: User }) {
    console.log('actions:addUser'); // eslint-disable-line no-console

    const { email, password, ...data } = user;

    const createUser = firebase.functions().httpsCallable('createUser');
    const result = await createUser({ email, password });
    console.log('result =', result); // eslint-disable-line no-console

    const { uid } = result.data as CreateUserResult;

    const db = firebase.firestore();

    const collectionRef = db.collection('users');

    const newUser = {
      ...data,
      email,
      uid,
    } as User;

    return collectionRef.add(newUser);
  }

  @Action({ rawError: true })
  async updateUser({ user }: { user: User }) {
    console.log('actions:updateUser'); // eslint-disable-line no-console

    if ('uid' in user && 'email' in user && 'password' in user) {
      const updateUser = firebase.functions().httpsCallable('updateUser');
      const result = await updateUser({
        uid: user.uid,
        email: user.email,
        password: user.password,
      });
      console.log('result =', result); // eslint-disable-line no-console
    }

    const db = firebase.firestore();

    // Separa 'id' dal resto
    const { id, ...data } = user;

    const docRef = db.doc(`/users/${id}`);

    return docRef.update({
      ...data,
    });
  }

  @Action
  async deleteUser({ user }: { user: User }) {
    console.log('actions:deleteUser'); // eslint-disable-line no-console

    const { id, uid } = user;

    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    const result = await deleteUser({ uid });
    console.log('result =', result); // eslint-disable-line no-console

    // Recupera il riferimento all'utente
    const db = firebase.firestore();
    const docRef = db.doc(`/users/${id}`);

    // Rimuove l'utente
    return docRef.delete();
  }

  @Action
  async getUserById(id: string): Promise<User | null> {
    console.log('actions:getUserById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/users/${id}`);

    const doc = await docRef.get();

    if (!doc.exists) return null;

    return {
      id: doc.id,
      ...doc.data(),
    } as User;
  }
}
