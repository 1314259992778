




























































import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { School } from '@/types';

@Component
export default class SchoolImage extends Vue {
  @Prop(Object) readonly value: School|null|undefined

  updating = false

  school = null as School|null

  @Watch('school')
  onSchoolChanged(val: School | null, oldVal: School | null) {
    console.log('SchoolImage: watch.school()'); // eslint-disable-line no-console
    this.$emit('input', val);
  }

  created() {
    console.log('SchoolImage: created()'); // eslint-disable-line no-console
    if (this.value !== undefined) {
      this.school = this.value;
    }
  }

  get hasImage() {
    return this.school && 'imageUrl' in this.school && this.school.imageUrl !== null;
  }

  async remove() {
    this.updating = true;

    // Se stiamo modificando un prodotto esistente...
    if (this.school && this.school.id) {
      // ... elimina l'immagine anche da Firebase
      await this.$store.dispatch('removeSchoolImage', {
        schoolId: this.school.id,
      });
    }

    this.$set(this.school as School, 'imageUrl', null);
    this.$set(this.school as School, 'imageName', null);

    const fileInput = this.$refs.fileInput as HTMLInputElement;

    fileInput.value = '';

    this.updating = false;
  }

  upload() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
  }

  async handleFileChange(evt: InputEvent) {
    if (!this.school || !this.school.id || !evt || !evt.target) return;

    const target = evt.target as HTMLInputElement;

    if (!target.files) return;

    console.log('evt.target.files =', target.files); // eslint-disable-line no-console

    if (target.files.length === 1) {
      this.updating = true;

      const { imageUrl, imageName } = await this.$store.dispatch('updateSchoolImage', {
        schoolId: this.school.id,
        file: target.files[0],
      });

      if (imageUrl && imageName) {
        this.$set(this.school as School, 'imageUrl', imageUrl);
        this.$set(this.school as School, 'imageName', imageName);
      }

      this.updating = false;
    }
  }
}
