/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { School } from '@/types';

@Module
export default class Schools extends VuexModule {
  data: School[] = []

  schoolsListener: (() => void) | null = null

  get schools(): School[] {
    return Object.values(this.data);
  }

  @Mutation
  setSchools({ snapshot }: {
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  }) {
    const data: School[] = [];

    if (snapshot) {
      snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
        } as School);
      });
    }

    this.data = data;
  }

  @Mutation
  setSchoolsListener({ listener }: { listener: (() => void) | null }) {
    this.schoolsListener = listener;
  }

  @Action
  initSchools() {
    console.log('actions:initSchools'); // eslint-disable-line no-console
    return this.context.dispatch('updateSchools');
  }

  @Action
  destroySchools() {
    console.log('actions:destroySchools'); // eslint-disable-line no-console
    // Disattiva l'eventuale listener esistente
    if (this.schoolsListener) this.schoolsListener();
  }

  @Action
  async updateSchools() {
    console.log('actions:updateSchools'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (this.schoolsListener) this.schoolsListener();

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('schools');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (schools)'); // eslint-disable-line no-console

      this.context.commit('setSchools', { snapshot });
    });

    this.context.commit('setSchoolsListener', { listener });
  }

  @Action
  async addSchool({ school }: { school: School }) {
    console.log('actions:schools/add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('schools');

    const newSchool = {
      ...school,
    } as School;

    return collectionRef.add(newSchool);
  }

  @Action({ rawError: true })
  async updateSchool(update: Record<string, string|boolean>) {
    console.log('actions:updateSchool'); // eslint-disable-line no-console
    console.log('actions:updateSchool update =', update); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = update;

    const docRef = db.doc(`/schools/${id}`);

    return docRef.update({
      ...data,
    });
  }

  @Action
  async deleteSchool(id: string) {
    console.log('actions:schools/delete'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = firebase.firestore();
    const docRef = db.doc(`/schools/${id}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const doc = await docRef.get();
    const docData = doc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove il file dallo storage
    if (imageName) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(imageName);
      await imageRef.delete();
    }

    // Rimuove il prodotto
    return docRef.delete();
  }

  @Action
  async getSchoolById(id: string): Promise<School | null> {
    console.log('actions:schools/getById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/schools/${id}`);

    const doc = await docRef.get();

    if (!doc.exists) return null;

    return {
      id: doc.id,
      ...doc.data(),
    } as School;
  }

  @Action
  async updateSchoolImage({ schoolId, file }: { schoolId: string; file: File }) {
    console.log('actions:updateSchoolImage'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Se stiamo modificando un prodotto esistente...
    if (schoolId) {
      // ... elimina l'eventuale immagine precedente

      // Recupera il nome dell'immagine da eliminare dallo storage
      const docRef = db.doc(`/schools/${schoolId}`);
      const doc = await docRef.get();
      const docData = doc.data();
      const imageName = docData ? docData.imageName : null;

      // Rimuove il file dallo storage (se presente)
      if (imageName) {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(imageName);
        await imageRef.delete();
      }
    }

    const uploadImage = (imageName: string) => new Promise((resolve, reject) => {
      // Inizia l'upload del file verso lo storage
      const storageRef = firebase.storage().ref();
      const uploadRef = storageRef.child(imageName);
      const uploadTask = uploadRef.put(file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused'); // eslint-disable-line no-console
              break;
            case firebase.storage.TaskState.RUNNING: { // or 'running'
              // console.log('Upload is running'); // eslint-disable-line no-console
              const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${uploadProgress}% done`); // eslint-disable-line no-console
              break;
            }
            default:
          }
        }, (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // state.importErrors.push(error.code);
              break;

            case 'storage/canceled':
              // User canceled the upload
              // state.importErrors.push(error.code);
              break;

            case 'storage/unknown':
            default:
              // Unknown error occurred, inspect error.serverResponse
              // state.importErrors.push(error.code);
              break;
          }

          return reject(error);
        }, () => {
          console.log('Upload complete'); // eslint-disable-line no-console

          return uploadTask.snapshot.ref.getDownloadURL().then(
            (downloadURL) => resolve(downloadURL),
          );
        });
    });

    // Prepara il nome del file della nuova immagine
    const imageName = `images/schools/${uuidv4()}-${file.name}`;

    // Carica l'immagine nello storage
    const imageUrl = await uploadImage(imageName);

    // eslint-disable-next-line no-console
    console.log('URL dell\'immagine:', imageUrl);

    // Memorizza il riferimento all'immagine nel prodotto
    if (schoolId) {
      const docRef = db.doc(`/schools/${schoolId}`);

      await docRef.update({
        imageUrl,
        imageName,
      });
    }

    return Promise.resolve({
      imageUrl,
      imageName,
    });
  }

  @Action
  async removeSchoolImage({ schoolId }: { schoolId: string }) {
    console.log('actions:removeSchoolImage'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = firebase.firestore();
    const docRef = db.doc(`/schools/${schoolId}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const doc = await docRef.get();
    const docData = doc.data();
    const imageName = docData ? docData.imageName : null;

    // Rimuove i riferimenti all'immagine nel prodotto
    await docRef.update({
      imageUrl: null,
      imageName: null,
    });

    // Rimuove il file dallo storage
    if (imageName) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(imageName);
      await imageRef.delete();
    }

    return Promise.resolve();
  }
}
