import Vue from 'vue';
import Vuex from 'vuex';

import Auth from './modules/auth';
import Students from './modules/students';
import Maneuvers from './modules/maneuvers';
import Places from './modules/places';
import Schools from './modules/schools';
import Users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  modules: {
    auth: Auth,
    students: Students,
    maneuvers: Maneuvers,
    places: Places,
    schools: Schools,
    users: Users,
  },
});
