










/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { Student } from '@/types';

@Component
export default class AlunnoNote extends Vue {
  @Prop(Object) readonly student!: Student;

  get notes(): string {
    return this.student && 'notes' in this.student && this.student.notes
      ? this.student.notes
      : '';
  }
}
