



















































import Vue from 'vue';
import AlunnoCondotte from '@/components/AlunnoCondotte.vue';
import AlunnoLuoghi from '@/components/AlunnoLuoghi.vue';
import AlunnoNote from '@/components/AlunnoNote.vue';
import { Student, Maneuver, Place } from '@/types';

export default Vue.extend({
  name: 'Alunno' as string,

  components: {
    AlunnoCondotte,
    AlunnoLuoghi,
    AlunnoNote,
  },

  data: () => ({
    tab: null,

    loading: true,

    student: {} as Student,
  }),

  async created() {
    this.loading = true;

    console.log('this.$route.params = ', this.$route.params); // eslint-disable-line no-console

    const { student, maneuvers, places } = await this.$store.dispatch('getStudent', {
      schoolId: this.$route.params.schoolId,
      studentId: this.$route.params.studentId,
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!student) this.$router.replace({ name: 'Home' }, () => {});

    this.student = {
      ...student,
      maneuvers,
      places,
    };

    this.loading = false;

    // if (this.$analytics) this.$analytics.logEvent('view_item_list');
  },

  computed: {
    name(): string {
      return `${this.student.firstName} ${this.student.lastName}`;
    },
  },

  methods: {
  },
});
