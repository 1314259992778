



























































import Vue from 'vue';
import { NavigationGuardNext } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';
import NewUser from '@/components/User.vue';

export default Vue.extend({
  name: 'NuovoUtente' as string,

  components: {
    PageHeader,
    NewUser,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Utenti',
        exact: true,
        to: { name: 'Utenti' },
      },
      {
        text: 'Nuovo',
      },
    ],

    updating: false,

    user: {
      firstName: '',
      lastName: '',
      schoolId: null,
      email: '',
      password: '',
    },

    updated: false,
    leaveDialog: false,
    next: null as NavigationGuardNext | null,
  }),

  methods: {
    cancel() {
      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Utenti' }, () => {});
    },

    async add() {
      this.updating = true;
      this.updated = false;

      await this.$store.dispatch('addUser', {
        user: this.user,
      });

      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Utenti' }, () => {});
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
});
