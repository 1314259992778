/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
import { Place } from '@/types';

function compareOrder(item: Place, otherItem: Place) {
  if (item.order === undefined || otherItem.order === undefined) return 0;

  if (item.order < otherItem.order) {
    return -1;
  }
  if (item.order > otherItem.order) {
    return 1;
  }
  return 0;
}

function getPlaceOrder(
  order: firebase.firestore.DocumentData | undefined,
  id: string,
  count: number,
) {
  // eslint-disable-next-line no-prototype-builtins
  return !order || !order.hasOwnProperty(id) ? count : order[id];
}

@Module
export default class Places extends VuexModule {
  @Action({ rawError: true })
  async getPlaces({ schoolId }: { schoolId: string }) {
    console.log('actions:places/getPlaces'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento con l'ordinamento dei luoghi
    const placesOrderDocRef = db.collection(`/schools/${schoolId}/orderMaps`).doc('places');
    const orderDoc = await placesOrderDocRef.get();
    const order = orderDoc.data();

    // Recupera il documento associato allo studente
    const placesCollectionRef = db.collection(`/schools/${schoolId}/places`);

    const snapshot = await placesCollectionRef.get();

    const data: Place[] = [];
    let count = 0;

    snapshot.forEach((doc) => {
      const docData = doc.data();

      data.push({
        id: doc.id,
        ...docData,
        order: getPlaceOrder(order, doc.id, count),
      } as Place);

      count += 1;
    });

    data.sort(compareOrder);

    return data;
  }
}
