/* eslint-disable no-console */

import Vue from 'vue';
import firebase from 'firebase';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

Vue.config.productionTip = false;

let app: Vue;

let firebaseConfig: Record<string, string>;

switch (process.env.NODE_ENV) {
  // case 'staging':
  //   firebaseConfig = {
  //     apiKey: '',
  //     authDomain: '',
  //     databaseURL: '',
  //     projectId: '',
  //     storageBucket: '',
  //     messagingSenderId: '',
  //     appId: '',
  //     // measurementId: '',
  //   };
  //   break;

  case 'production':
    firebaseConfig = {
      apiKey: 'AIzaSyAuh51O8eNxF_bHAwKMnCmIGlpUq2XeuGc',
      authDomain: 'studio-alfa.firebaseapp.com',
      databaseURL: 'https://studio-alfa.firebaseio.com',
      projectId: 'studio-alfa',
      storageBucket: 'studio-alfa.appspot.com',
      messagingSenderId: '789587463364',
      appId: '1:789587463364:web:340ee326d83e5d3c120d91',
      measurementId: 'G-Q7GLB3V6P0',
    };
    break;

  default:
    // Di default utilizza la configurazione per l'ambiente 'development'
    firebaseConfig = {
      apiKey: 'AIzaSyBWmd_u0TtwUQkGsNXMcYZNw2MYzdZagjM',
      authDomain: 'studio-alfa-dev.firebaseapp.com',
      databaseURL: 'https://studio-alfa-dev.firebaseio.com',
      projectId: 'studio-alfa-dev',
      storageBucket: 'studio-alfa-dev.appspot.com',
      messagingSenderId: '132823148383',
      appId: '1:132823148383:web:9b03619ff92228d16138ea',
      // measurementId: '',
    };
    break;
}

function initFirebase() {
  firebase.initializeApp(firebaseConfig);

  // eslint-disable-next-line no-prototype-builtins
  if (firebaseConfig && firebaseConfig.hasOwnProperty('measurementId')) {
    Vue.prototype.$analytics = firebase.analytics();
  }

  return Promise.resolve();

  // return new Promise((resolve, reject) => {
  //   firebase.firestore().enablePersistence()
  //     .then(resolve)
  //     .catch((err) => {
  //       console.log('firebase.firestore().enablePersistence() error');
  //       console.log(err);

  //       if (err.code === 'failed-precondition') {
  //         reject(err);
  //         // Multiple tabs open, persistence can only be
  //         // enabled in one tab at a a time.
  //       } else if (err.code === 'unimplemented') {
  //         reject(err);
  //         // The current browser does not support all of
  //         // the features required to enable persistence
  //       }
  //     });
  // });
}

// initFirebase
initFirebase()
  .catch((err) => {
    console.log('initFirebase() error');
    console.log(err);
  });

// eslint-disable-next-line no-unused-vars
firebase.auth().onAuthStateChanged(async (user) => {
  console.log('firebase.auth().onAuthStateChanged()');
  console.log('user =', user);

  store.dispatch('fetchUser', user);

  if (user) {
    await store.dispatch('initSchools');
    await store.dispatch('initUsers');
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
