














import Vue from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import Schools from '@/components/Schools.vue';

export default Vue.extend({
  name: 'Scuole' as string,

  components: {
    PageHeader,
    Schools,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Scuole',
        exact: true,
        to: { name: 'Scuole' },
      },
    ],

    dialog: false,
  }),
});
