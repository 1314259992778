/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
import { Maneuver, Place } from '@/types';

@Module
export default class Students extends VuexModule {
  @Action({ rawError: true })
  async getStudent({ schoolId, studentId }: { schoolId: string; studentId: string }) {
    console.log('actions:students/getStudent'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento associato allo studente
    const studentDocRef = db.doc(`/schools/${schoolId}/students/${studentId}`);

    const studentDoc = await studentDocRef.get();
    const student = studentDoc.data();

    // Recupera le condotte dello studente
    const maneuversCollectionRef = db.collection(`/schools/${schoolId}/students/${studentId}/maneuvers`);
    const snapshot = await maneuversCollectionRef.get();

    const maneuvers: Maneuver[] = [];
    snapshot.forEach((doc) => {
      const docData = doc.data();
      maneuvers.push({
        id: doc.id,
        ...docData,
      } as Maneuver);
    });

    // Recupera i luoghi dello studente
    const placesCollectionRef = db.collection(`/schools/${schoolId}/students/${studentId}/places`);
    const placesSnapshot = await placesCollectionRef.get();

    const places: Place[] = [];
    placesSnapshot.forEach((doc) => {
      const docData = doc.data();
      places.push({
        id: doc.id,
        ...docData,
      } as Place);
    });

    return {
      student,
      maneuvers,
      places,
    };
  }
}
