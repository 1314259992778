/* eslint-disable class-methods-use-this */

import {
  Module, VuexModule, Action,
} from 'vuex-module-decorators';
import firebase from 'firebase';
import { Maneuver } from '@/types';

function compareOrder(item: Maneuver, otherItem: Maneuver) {
  if (item.order === undefined || otherItem.order === undefined) return 0;

  if (item.order < otherItem.order) {
    return -1;
  }
  if (item.order > otherItem.order) {
    return 1;
  }
  return 0;
}

function getManeuverOrder(
  order: firebase.firestore.DocumentData | undefined,
  id: string,
  count: number,
) {
  // eslint-disable-next-line no-prototype-builtins
  return !order || !order.hasOwnProperty(id) ? count : order[id];
}

@Module
export default class Maneuvers extends VuexModule {
  @Action({ rawError: true })
  async getManeuvers({ schoolId }: { schoolId: string }) {
    console.log('actions:maneuvers/getManeuvers'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento con l'ordinamento delle manovre
    const maneuversOrderDocRef = db.collection(`/schools/${schoolId}/orderMaps`).doc('maneuvers');
    const orderDoc = await maneuversOrderDocRef.get();
    const order = orderDoc.data();

    // Recupera il documento associato allo studente
    const maneuversCollectionRef = db.collection(`/schools/${schoolId}/maneuvers`);

    const snapshot = await maneuversCollectionRef.get();

    const data: Maneuver[] = [];
    let count = 0;

    snapshot.forEach((doc) => {
      const docData = doc.data();

      data.push({
        id: doc.id,
        ...docData,
        order: getManeuverOrder(order, doc.id, count),
      } as Maneuver);

      count += 1;
    });

    data.sort(compareOrder);

    return data;
  }
}
