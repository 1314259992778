


































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { Maneuver, Student } from '@/types';
import Rating from '@/components/Rating.vue';

@Component({
  components: {
    Rating,
  },
})
export default class AlunnoCondotte extends Vue {
  @Prop(Object) readonly student!: Student;

  loading = true
  maneuvers: Maneuver[] = []

  async created() {
    this.loading = true;

    console.log('this.$route.params = ', this.$route.params); // eslint-disable-line no-console

    this.maneuvers = await this.$store.dispatch('getManeuvers', {
      schoolId: this.$route.params.schoolId,
    });

    this.loading = false;
  }

  maneuverDetails(id: string) {
    return this.maneuvers.find((m: any) => m.id === id);
  }

  maneuverName(id: string) {
    const details = this.maneuverDetails(id);
    return details !== undefined && 'name' in details ? details.name : '';
  }

  maneuverRating(maneuver: Maneuver) {
    if (!Array.isArray(this.maneuvers)) return null;

    return this.student.maneuvers.find((element: any) => element.id === maneuver.id);
  }

  done(rating: any) {
    // Manca l'oggetto, condotta non eseguita
    if (!rating) return false;

    if ('count' in rating && rating.count > 0) return true;

    if ('date' in rating && rating.date) return true;

    return false;
  }
}
