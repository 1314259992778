import { render, staticRenderFns } from "./AlunnoCondotte.vue?vue&type=template&id=7ced313e&scoped=true&"
import script from "./AlunnoCondotte.vue?vue&type=script&lang=ts&"
export * from "./AlunnoCondotte.vue?vue&type=script&lang=ts&"
import style0 from "./AlunnoCondotte.vue?vue&type=style&index=0&id=7ced313e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ced313e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardTitle,VCheckbox,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle})
