






































































































/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { User, School } from '@/types';

@Component({
  components: {
  },
})
export default class UserComponent extends Vue {
  @Prop(Object) readonly value!: User|null

  @Prop({ type: Boolean, default: false }) readonly updating!: boolean

  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  @Prop({ type: Boolean, default: false }) readonly isNew!: boolean

  user = null as User|null

  valid = false as boolean

  passwordCheck = '' as string;

  get passwordRules() {
    const rules = [];

    rules.push((v: string) => !!v || 'La password è richiesta');

    rules.push((v: string) => v.length > 5 || 'La password deve essere lunga almeno 6 caratteri');

    return rules;
  }

  get passwordCheckRules() {
    const rules = [];

    rules.push((v: string) => !this.user || !this.user.password || !!v || 'La password va ripetuta');

    rules.push((v: string) => (this.user && v === this.user.password) || 'La password non corrisponde');

    return rules;
  }

  get schools(): School[] {
    return this.$store.getters.schools;
  }

  @Watch('value')
  onValueChanged(val: User | null, oldVal: User | null) {
    console.log('User: watch.value()'); // eslint-disable-line no-console
    console.log('User: watch.value() val =', val); // eslint-disable-line no-console
    console.log('User: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
    this.user = val;
  }

  async created() {
    console.log('User: created()'); // eslint-disable-line no-console
    console.log('User: created() this.value =', this.value); // eslint-disable-line no-console
    this.user = this.value;
  }

  // eslint-disable-next-line class-methods-use-this
  userUpdated() {
    console.log('School: userUpdated()'); // eslint-disable-line no-console

    if (!this.isNew) {
      this.$emit('updated');
    } else {
      const formRef = this.$refs.form;

      if ((formRef as any).validate()) {
        this.$emit('updated');
      }
    }
  }
}
