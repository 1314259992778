

















































































import Vue from 'vue';
import firebase from 'firebase';

export default Vue.extend({
  name: 'App' as string,

  data: () => ({
    drawer: false,
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    async logout() {
      await this.$store.dispatch('destroySchools');
      await this.$store.dispatch('destroyUsers');

      firebase.auth().signOut().then(() => {
        console.log('firebase.auth().signOut() success'); // eslint-disable-line no-console

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.$router.replace({ name: 'Login' }, () => {});
      });
    },
  },
});
