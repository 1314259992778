





























































import Vue from 'vue';
import { NavigationGuardNext } from 'vue-router';
import Loading from '@/components/Loading.vue';
import PageHeader from '@/components/PageHeader.vue';
import EditUser from '@/components/User.vue';
import { User, School } from '@/types';

export default Vue.extend({
  name: 'Utente' as string,

  components: {
    Loading,
    PageHeader,
    EditUser,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,

    breadcrumbs: [
      {
        text: 'Utenti',
        exact: true,
        to: { name: 'Utenti' },
      },
    ],

    id: null as string | null,
    user: null as User | null,

    leaveDialog: false,
    next: null as NavigationGuardNext | null,
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const user = await this.$store.dispatch('getUserById', this.id);

    this.user = {
      id: this.id,
      ...user,
    } as User;

    // Inserisce il nome della scuola nei breadcrumbs
    this.breadcrumbs.push({
      text: this.user.email || '(indirizzo email sconosciuto)',
      exact: false,
      to: { name: 'Utente' },
    });

    this.loading = false;
  },

  computed: {
    schools(): School[] {
      return this.$store.getters.schools;
    },
  },

  methods: {
    onCancel() {
      // Torna all'elenco delle scuole
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.$router.replace({ name: 'Utenti' }, () => {});
    },

    async update() {
      if (this.user && this.user.email && this.id) {
        this.updating = true;

        const update = {
          id: this.id,
          email: this.user.email,
        };

        await this.$store.dispatch('updateSchool', update);

        // Aggiorna il nome della scuola nei breadcrumbs
        this.breadcrumbs.pop();
        this.breadcrumbs.push({
          text: this.user.email || '(indirizzo email sconosciuto)',
          exact: false,
          to: { name: 'Utente' },
        });

        this.updated = false;
        this.updating = false;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
});
