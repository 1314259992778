
















import Vue from 'vue';

export default Vue.extend({
  name: 'Stars',

  props: ['rating'],

  data: () => ({
  }),

  computed: {
    cleanRating() {
      if (!this.rating) return 0;
      if (this.rating > 3) return 3;
      return this.rating;
    },
  },

  methods: {
  },
});
