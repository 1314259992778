






























































































import Vue from 'vue';
import { School } from '@/types';
// import DeleteSchoolDialog from '@/components/DeleteSchoolDialog.vue';

export default Vue.extend({
  name: 'Schools' as string,

  components: {
    // DeleteSchoolDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null as School | null,

    headers: [
      { text: 'Nome della scuola', value: 'name', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ],

    filters: {
      search: '',
      archived: false,
    },
  }),

  computed: {
    schools(): School[] {
      return this.$store.getters.schools;
    },

    filteredSchools() {
      return (this as any).schools
        ? (this as any).schools.filter((s: School) => {
          // Filtro sugli archiviati
          if (!this.filters.archived && s.archived) return false;

          // TODO: Campo di ricerca
          if (this.filters.search && this.filters.search.trim()
            && !s.name
              .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

          return true;
        })
        : [];
    },
  },

  methods: {
    onDelete(school: School) {
      this.selected = school;
      this.deleteDialog = true;
    },

    onEdit(school: School) {
      if (school && school.id) {
        this.$router.push({ name: 'Scuola', params: { id: school.id } });
      }
    },
  },
});
