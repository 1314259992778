



































import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { School } from '@/types';
import SchoolImage from '@/components/SchoolImage.vue';

@Component({
  components: {
    SchoolImage,
  },
})
export default class SchoolComponent extends Vue {
  @Prop(Object) readonly value!: School|null

  @Prop({ type: Boolean, default: false }) readonly updating!: boolean

  @Prop({ type: Boolean, default: false }) readonly loading!: boolean

  id = null as string|null

  school = null as School|null

  @Watch('value')
  onValueChanged(val: School | null, oldVal: School | null) {
    console.log('School: watch.value()'); // eslint-disable-line no-console
    console.log('School: watch.value() val =', val); // eslint-disable-line no-console
    console.log('School: watch.value() oldVal =', oldVal); // eslint-disable-line no-console
    this.school = val;
  }

  async created() {
    console.log('School: created()'); // eslint-disable-line no-console
    console.log('School: created() this.value =', this.value); // eslint-disable-line no-console
    this.school = this.value;
  }

  // eslint-disable-next-line class-methods-use-this
  schoolUpdated() {
    console.log('School: schoolUpdated()'); // eslint-disable-line no-console
    this.$emit('updated');
  }
}
