


































































































/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { User, School } from '@/types';
import DeleteUserDialog from '@/components/DeleteUserDialog.vue';

@Component({
  components: {
    DeleteUserDialog,
  },
})
export default class Users extends Vue {
  // newDialog: false,

  // renameDialog: false,

  deleteDialog = false

  selected = null as User | null

  headers = [
    { text: 'Nome', value: 'name', sortable: false },
    { text: 'Scuola', value: 'school', sortable: false },
    { text: '', value: 'isSuper', sortable: false },
    {
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    },
  ]

  filters = {
    search: '',
  }

  get users(): User[] {
    return this.$store.getters.users;
  }

  get schools(): School[] {
    return this.$store.getters.schools;
  }

  get isSearchBlank() {
    return (!this.filters.search || /^\s*$/.test(this.filters.search));
  }

  get filteredUsers() {
    if (this.isSearchBlank) return (this as any).users;

    return (this as any).users
      ? (this as any).users.filter((u: User) => {
        if (!u) return false;

        const userStrings = [
          u.firstName,
          u.lastName,
          `${u.firstName} ${u.lastName}`,
          `${u.lastName} ${u.firstName}`,
          this.schoolName(u),
        ];

        let found = false;
        userStrings.forEach((str) => {
          if (typeof str === 'string' && typeof this.filters.search === 'string'
            && str.toLowerCase().includes(this.filters.search.toLowerCase())) found = true;
        });

        return found;
      })
      : [];
  }

  fullName(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  schoolName(user: User) {
    const school = this.schools.find((s) => s.id === user.schoolId);
    return school && school.name ? school.name : '';
  }

  onDelete(user: User) {
    this.selected = user;
    this.deleteDialog = true;
  }

  onEdit(user: User) {
    if (user && user.id) {
      this.$router.push({ name: 'Utente', params: { id: user.id } });
    }
  }
}
